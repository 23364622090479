import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import {graphql, PageProps} from "gatsby"
import HeroInterface from "../interfaces/hero.interface"

import ReactMarkdown from "react-markdown"
import {ContentPageInterface} from "../interfaces/content-page.interface";
import LanguageMenu from "../components/language-menu";
import logo from "../images/allianz-logo.svg";


type ImprintPageProps = PageProps<{
    strapiHomepage: {
        siteName: string
        stage: HeroInterface
    }
    strapiImprint: ContentPageInterface
}>

const ImprintPage = ({data}: ImprintPageProps) => {
  const { strapiImprint } = data

  return (
    <Layout>
      <Seo title={strapiImprint.title} />
        <header className="snap-start w-screen relative text-center flex bg-stage bg-fixed bg-cover bg-center">
            <div className="absolute w-full top-5">
                <LanguageMenu />
            </div>
            <div className="m-auto my-20 items-center">
                <a href="/" className="block">
                    <img
                        src={logo}
                        alt="Allianz Logo"
                        className="w-40 mb-12 m-auto"
                    />
                </a>

                <h1 className="text-primary text-4xl font-bold uppercase">
                    {strapiImprint.title}
                </h1>
            </div>
        </header>

        <main className="min-h-screen container mx-auto max-w-4xl">
            <div className="p-10 text-black prose lg:prose-xl">
                <ReactMarkdown children={strapiImprint.content} />
            </div>
        </main>

    </Layout>
  )
}

export default ImprintPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    
    strapiHomepage(locale: { eq: $language }) {
      siteName
      stage {
        title
        text
      }
    }
    
    strapiImprint(locale: { eq: $language }) {
        title
        content
    }
  
  }
`
